


















































import {Component, Inject, Prop, Vue, Watch} from 'vue-property-decorator'
import {InputSelect} from '@simpli/vue-input'
import {Dapp} from '@/model/resource/Dapp'
import {DappCollection} from '@/model/collection/DappCollection'
import {debounce} from 'lodash'
import VueMultiselect from 'vue-multiselect/src/Multiselect.vue'

@Component({
  components: {VueMultiselect, InputSelect},
})
export default class SelectMultipleDapps extends Vue {
  @Prop({type: [Array, Object]})
  value!: Dapp[] | Dapp

  @Prop({type: DappCollection, default: () => new DappCollection()})
  collection!: DappCollection

  @Prop({type: String, default: null})
  label!: string | null

  @Prop({type: String, default: null})
  name!: string | null

  @Prop({type: String, default: null})
  inputClass!: string | null

  @Prop({type: Boolean, default: false})
  required!: boolean

  @Prop({type: Boolean, default: false})
  disabled!: boolean

  @Prop({type: String, default: ''})
  placeholder!: string

  @Prop({type: String, default: null})
  noOptionsLabel!: string | null

  @Prop({type: String, default: null})
  noResultLabel!: string | null

  @Prop({default: null})
  validation!: any

  @Prop({type: Boolean, default: true})
  cleanable!: boolean

  @Prop({type: Boolean, default: true})
  multiple!: boolean

  @Prop({type: Boolean, default: false})
  showLabels!: boolean

  @Inject({from: 'validator', default: null})
  validator: any

  readonly DEBOUNCE_TIMER = 500
  debouncedFind = debounce(
    async (search: string) => await this.find(search),
    this.DEBOUNCE_TIMER
  )

  isLoading = false

  mounted() {
    this.collection.noPagination()
  }

  get uid() {
    return this.$utils.uid()
  }

  get computedModel() {
    return this.value
  }

  set computedModel(val: Dapp[] | Dapp | null) {
    this.$emit('input', val)
  }

  get isInvalid() {
    // @ts-ignore
    return this.errors.first(this.label ?? '')
  }

  get computedName() {
    return this.name || this.label || '-'
  }

  get isCleanable() {
    return (
      this.cleanable &&
      this.computedModel &&
      (this.computedModel as Dapp[]).length !== 0
    )
  }

  async find(search: string) {
    if (search.length > 2) {
      this.isLoading = true

      const copy = [...this.collection.items]
      this.collection.setSearch(search)
      await this.collection.queryAsPage()
      const notRepeatedCopy = copy.filter(
        iInCopy =>
          !this.collection.items.find(
            iInCollection => iInCollection.$id === iInCopy.$id
          )
      )
      this.collection.items = [...this.collection.items, ...notRepeatedCopy]
      this.isLoading = false
    }
  }

  customLabel(dapp: Dapp) {
    return dapp.$tag
  }

  emitEmpty() {
    this.$emit('input', null)
  }
}
