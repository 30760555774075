/**
 * Input Schema of Contract
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {Contract} from '@/model/resource/Contract'
import {DappCollection} from '@/model/collection/DappCollection'
import SelectMultipleDapps from '@/components/SelectMultipleDapps.vue'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'

/* TODO: review generated schema */
export class InputContractSchema extends DefaultSchema {
  collectionDapp = new DappCollection().noPagination()

  get editModeFields() {
    return ['title', 'dapp']
  }

  get contractEditModeFields() {
    return ['title', 'dapp', 'imageUrl']
  }

  readonly name = 'InputContract'

  readonly fieldSet: FieldSet<Contract> = {
    dapp: (schema): FieldComponent => ({
      is: SelectMultipleDapps,
      bind: {
        collection: this.collectionDapp,
        label: this.translateFrom(schema.fieldName),
        multiple: false,
        showLabels: false,
      },
    }),
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 70,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
        inputClass: 'text-xl text-black',
      },
    }),
    hash: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 64,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    imageUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'contract',
        compressDimension: $.auth.global.dappImageDimension,
        crop: true,
        class: 'scoped__image-url w-full',
      },
    }),
  }
}
